import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';

export const MuokkausTila = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
};

function NaytaPainike({ valitsePaivat }) {
  return (
    <Button variant="contained" color="secondary" onClick={valitsePaivat}>
      <FormattedMessage defaultMessage={'Valitse toimistopäivät'} />
    </Button>
  );
}

function MuokkausPainikkeet({ tallenna, peruuta }) {
  return (
    <>
      <Button variant="outlined" onClick={peruuta}>
        <FormattedMessage defaultMessage={'Peruuta'} />
      </Button>
      <Button variant="contained" color="secondary" onClick={tallenna}>
        <FormattedMessage defaultMessage={'Tallenna'} />
      </Button>
    </>
  );
}

export default function KalenteriKontrollit({
  muokkausTila,
  tallenna,
  peruuta,
  valitsePaivat,
}) {
  let kontrollit = null;
  switch (muokkausTila) {
    case MuokkausTila.EDIT:
      kontrollit = (
        <MuokkausPainikkeet
          nayta={MuokkausTila.EDIT}
          tallenna={tallenna}
          peruuta={peruuta}
        />
      );
      break;
    case MuokkausTila.VIEW:
      kontrollit = (
        <NaytaPainike nayta={MuokkausTila.VIEW} valitsePaivat={valitsePaivat} />
      );
      break;
    default:
      kontrollit = null;
      break;
  }
  return (
    <Grid sx={{ display: 'flex' }} justifyContent="flex-end">
      <Stack sx={{ my: 1 }} spacing={2} direction="row">
        {kontrollit}
      </Stack>
    </Grid>
  );
}
