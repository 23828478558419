import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { Grid, Typography } from '@mui/material';
import MUILink from '@mui/material/Link';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import OsallistumisLinkki from './OsallistumisLinkki';

export default function ToimistoKontrollit({ toimisto, toimistonNimiVariant }) {
  return (
    <Grid direction="column" container maxWidth={'sm'}>
      <Grid item>
        <small>
          <OsallistumisLinkki toimisto={toimisto} />
        </small>
      </Grid>
      <Grid item container alignItems="baseline" sx={{ py: 1 }} spacing={2}>
        <Grid item>
          <Typography variant={toimistonNimiVariant} component="h2">
            {toimisto.nimi}
          </Typography>
        </Grid>
        <Grid item>
          <MUILink
            component={Link}
            sx={{ fontWeight: 'medium' }}
            color="black"
            underline="hover"
            to="/"
          >
            <FormattedMessage defaultMessage="Vaihda toimistoa" />
          </MUILink>
          <SwapHorizIcon sx={{ pt: 1 }} />
        </Grid>
      </Grid>
    </Grid>
  );
}
