import MUIAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import Linkit from './Linkit';
import LogoNimella from './LogoNimella';

export default function BottomAppBar() {
  return (
    <Box sx={{ flexGrow: 1, mb: 10 }}>
      <MUIAppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar>
          <LogoNimella />
          <Linkit />
        </Toolbar>
      </MUIAppBar>
    </Box>
  );
}
