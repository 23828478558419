import {
  useOsallistumiset,
  refetchOsallistumiset,
  postOsallistumiset,
} from 'api';
import { useState } from 'react';
import {
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  isSameDay,
} from 'date-fns';
import useKayttaja from './useKayttaja';

/*
Osallistuminen:
{
    "pvm": "2021-09-16",
    "osallistujaId": "0f8715af-83a2-4333-a26c-c7adcb657dea",
    "vari": "string"
}
*/

function laskePaikallaJaPoissa(monthStart, events, eventsDraft) {
  function onOsallistuminen(osallistuminen, start) {
    return osallistuminen.filter((o) => isSameDay(o.start, start)).length > 0;
  }

  // iteroi kuukauden päivät
  let paivat = eachDayOfInterval({
    start: startOfMonth(monthStart), //sanity check
    end: endOfMonth(monthStart),
  });

  return paivat.reduce(
    (acc, start) => {
      let onAlkuperaisessa = onOsallistuminen(events, start);
      let onLuonnoksessa = onOsallistuminen(eventsDraft, start);

      if (onAlkuperaisessa && !onLuonnoksessa) {
        acc.poissa.push(start);
      } else if (!onAlkuperaisessa && onLuonnoksessa) {
        acc.paikalla.push(start);
      }

      return acc;
    },
    { paikalla: [], poissa: [] }
  );
}

const filtteroiKayttajaIdlla = (kayttajaId) => (osallistuminen) => {
  return osallistuminen.osallistujaId === kayttajaId;
};

export function useValitunPaivanOsallistujat(
  toimistoKoodi,
  alku,
  loppu,
  valittuPaiva
) {
  let osallistumiset = useOsallistumiset(toimistoKoodi, alku, loppu);

  return osallistumiset.osallistumiset.filter((osallistuminen) => {
    return isSameDay(osallistuminen.start, valittuPaiva);
  });
}

function useOmatKalenteriOsallistumiset(
  toimistoKoodi,
  kayttajaId,
  alku,
  loppu
) {
  let osallistumiset = useOsallistumiset(toimistoKoodi, alku, loppu);
  if (Array.isArray(osallistumiset.osallistumiset)) {
    osallistumiset = osallistumiset.osallistumiset.filter(
      filtteroiKayttajaIdlla(kayttajaId)
    );
  }
  return osallistumiset;
}

/**
 * Implements commit/cancel functionality
 * for editing calendar.
 *
 * @returns
 */
export default function useKalenteri(toimistoKoodi, alku, loppu) {
  let { kayttajaId } = useKayttaja();
  let omatOsallistumiset = useOmatKalenteriOsallistumiset(
    toimistoKoodi,
    kayttajaId,
    alku,
    loppu
  );
  let [luonnos, setLuonnos] = useState([]);

  function tallenna() {
    const { paikalla, poissa } = laskePaikallaJaPoissa(
      alku,
      omatOsallistumiset,
      luonnos
    );
    postOsallistumiset(toimistoKoodi, paikalla, poissa).then(() => {
      refetchOsallistumiset(toimistoKoodi, alku, loppu);
      setLuonnos([]);
    });
  }

  function aloitaLuonnos() {
    setLuonnos(omatOsallistumiset);
  }

  function peruutaLuonnos() {
    setLuonnos([]);
  }

  function paivitaLuonnos(day) {
    let events = luonnos;
    function eventExists(day) {
      return events.findIndex((event) => isSameDay(event.start, day)) >= 0;
    }
    function removeEvent(day) {
      events = events.filter((event) => !isSameDay(event.start, day));
    }

    function addEvent(day) {
      events = [...events, { start: day }];
    }

    eventExists(day) ? removeEvent(day) : addEvent(day);

    setLuonnos(events);
  }

  return [
    omatOsallistumiset,
    luonnos,
    aloitaLuonnos,
    paivitaLuonnos,
    peruutaLuonnos,
    tallenna,
  ];
}

export function useOsallistujaMaarat(toimistoKoodi, alku, loppu) {
  let osallistumiset = useOsallistumiset(toimistoKoodi, alku, loppu);
  return Object.values(
    osallistumiset.osallistumiset.reduce((acc, cur) => {
      if (acc[cur.pvm]) {
        acc[cur.pvm].osallistujaMaara++;
      } else {
        acc[cur.pvm] = {
          osallistujaMaara: 1,
          start: cur.start,
        };
      }
      return acc;
    }, {})
  );
}
