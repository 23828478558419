import { formatISO, parseISO } from 'date-fns';
import * as client from './client';

export * from './client';

export function refetchOsallistumiset(toimistoKoodi, alku, loppu) {
  return client.refetchOsallistumiset({
    toimistoKoodi,
    alku: formatISO(alku, { representation: 'date' }),
    loppu: formatISO(loppu, { representation: 'date' }),
  });
}

/*
    DTO:
    {
        "pvm": "2021-09-16",
        "osallistujaId": "0f8715af-83a2-4333-a26c-c7adcb657dea",
        "vari": "string"
    }
    UI:
    {
        "pvm": "2021-09-16",
        "osallistujaId": "0f8715af-83a2-4333-a26c-c7adcb657dea",
        "vari": "string",
        "start": date(2021-09-16),
        "osallistujanNimi": "Seppo"
    }
*/
function mappaaOsallistuminen(osallistujienIdtJaNimet) {
  return function (osallistuminen) {
    return {
      ...osallistuminen,
      start: parseISO(osallistuminen.pvm),
      osallistujanNimi: osallistujienIdtJaNimet[osallistuminen.osallistujaId],
    };
  };
}

function mappaaOsallistujienIdtJaNimet(osallistujat) {
  return osallistujat.reduce((acc, osallistuja) => {
    acc[osallistuja.id] = osallistuja.nimi;
    return acc;
  }, {});
}

export function useOsallistumiset(toimistoKoodi, alku, loppu) {
  let osallistumiset = client.useOsallistumiset({
    toimistoKoodi,
    alku: formatISO(alku, { representation: 'date' }),
    loppu: formatISO(loppu, { representation: 'date' }),
  });

  let osallistujienIdtJaNimet = mappaaOsallistujienIdtJaNimet(
    osallistumiset.osallistujat
  );
  return {
    osallistumiset: osallistumiset.osallistumiset.map(
      mappaaOsallistuminen(osallistujienIdtJaNimet)
    ),
    osallistujat: osallistumiset.osallistujat,
  };
}

function mappaaOsallistuminenPost(start) {
  return {
    pvm: formatISO(start).split('T')[0],
    vari: null, // ei käytössä
  };
}
/**
 * postOsallistumiset- apille annetaan toimistokoodi,
 * ja lista paikallaolopäivistä ja poissaolopäivistä
 * date- muotoisina. Api hoitaa formatoinnin.
 *
 *
 * @param {String} toimistoKoodi
 * @param {Array<Date>} paikalla
 * @param {Array<Date>} poissa
 */
export function postOsallistumiset(toimistoKoodi, paikalla, poissa) {
  return client.postOsallistumiset(toimistoKoodi, {
    paikalla: paikalla.map(mappaaOsallistuminenPost),
    poissa: poissa.map(mappaaOsallistuminenPost),
  });
}

export async function paivitaToimisto(toimisto) {
  const response = await client.paivitaToimisto(null, toimisto);
  client.refetchToimistot();
  return response;
}
