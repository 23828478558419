import MUIAvatar from '@mui/material/Avatar';

function stringToColor(string) {
  let hash = 0;

  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Float väliltä 0-1
  let rand = parseFloat('0.' + Math.abs(hash));

  // https://martin.ankerl.com/2009/12/09/how-to-create-random-colors-programmatically/
  // PHI korvattu kivemmalla vakiolla.
  return `hsl(${Math.floor((rand / 0.33988749895) * 360)}, 100%, 80%)`;
}

function stringAvatar(name, sx) {
  name = name.toLocaleUpperCase();
  return {
    sx: {
      ...sx,
      bgcolor: stringToColor(name),
      color: 'black',
      fontWeight: 500,
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

/**
 *
 * @param {*} param0
 * @returns
 */
export default function Avatar({ kayttajaNimi, sx }) {
  return <MUIAvatar {...stringAvatar(kayttajaNimi, sx)} />;
}
