import * as React from 'react';
import Container from '@mui/material/Container';

import Toimistot from '../../components/Toimistot';
import LisaaToimisto from '../../components/LisaaToimisto';
import { Card } from '@mui/material';

export default function ValitseToimisto() {
  return (
    <Container maxWidth="xs">
      <Card sx={{ my: 4, p: 2 }}>
        <Toimistot />
        <LisaaToimisto />
      </Card>
    </Container>
  );
}
