import React from 'react';

import { add, startOfMonth, isSameDay } from 'date-fns';
// Must go before plugins
import FullCalendar from '@fullcalendar/react';
// FullCalendar plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import fiLocale from '@fullcalendar/core/locales/fi';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import './Kalenteri.css';
import { useRef } from 'react';
import { MuokkausTila } from './KalenteriKontrollit';
import { lahinArkipaiva } from 'hooks/kalenteriUtils';

// Locales can be customized
let _fiLocale = {
  ...fiLocale,
  weekText: 'Viikko ',
};

// hae näytettävän kuukauden ensimmäinen päivä
// aloituspäivän perusteella
function calculateDisplayedMonthStart(start) {
  // lisätään viikko aloituspäivään,
  // koska kalenteri voi näyttää
  // edellistä kuukautta, mutta ei
  // viikkoa enempää
  return startOfMonth(add(start, { days: 7 }));
}

export default function Kalenteri({
  events,
  validRange,
  dateClick,
  editState,
  eventClick,
  monthStart,
  setMonthStart,
  setValittuPaiva,
}) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('md'));
  const ref = useRef();

  return (
    <FullCalendar
      aspectRatio={isPhone ? 1 : 1.35}
      dateClick={dateClick}
      // callback kun kuukausi vaihtuu UI:ssa.
      // Huom. Kuukausi tarkistetaan koska muuten
      // kalenteri menee render-looppiin
      datesSet={function ({ start }) {
        let newMonthStart = calculateDisplayedMonthStart(start);
        if (!isSameDay(monthStart, newMonthStart)) {
          setMonthStart(newMonthStart);
        }
      }}
      eventClick={eventClick}
      events={events}
      fixedWeekCount={false}
      initialView="dayGridMonth"
      locale={_fiLocale}
      plugins={[dayGridPlugin, interactionPlugin]}
      headerToolbar={{
        start: 'prev,next'.concat(
          editState === MuokkausTila.VIEW ? ' tanaan' : ''
        ),
        center: 'title',
        end: '',
      }}
      customButtons={{
        tanaan: {
          text: 'Tänään',
          click: () => {
            // #20, Klikatessa "tänään" on muutettava myös
            // aktiivinen päivä, ei pelkästään näytetty kuukausi
            const seuraavaArkipaiva = lahinArkipaiva(new Date());
            const calendarApi = ref.current.getApi();
            setValittuPaiva(seuraavaArkipaiva);
            calendarApi.gotoDate(seuraavaArkipaiva);
          },
        },
      }}
      // älä näytä päiviä kuukauden ulkopuolelta
      showNonCurrentDates={false}
      validRange={validRange}
      weekNumbers={false}
      weekends={false}
      ref={ref}
    />
  );
}
