import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: '#eee',
    },
    primary: {
      main: '#424242',
      light: '#6d6d6d',
      dark: '#1b1b1b',
    },
    secondary: {
      main: '#0288d1',
      light: '#5eb8ff',
      dark: '#005b9f',
      contrastText: '#fff',
    },
    topbar: {
      main: '#fff',
      contrastText: '#000',
    },
  },
  // Muuten neliskanttiset muodot
  shape: {
    borderRadius: 0,
  },
  // ...mutta buttonit on pyöristettyjä
  // ks. https://mui.com/customization/theme-components/#heading-global-style-overrides
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
  },
});

export default function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
