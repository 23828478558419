import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Typography } from '@mui/material';
import { useCurrentUser } from '@visma/react-keycloak';
import { FormattedMessage } from 'react-intl';

export default function Logout() {
  const user = useCurrentUser();

  return (
    <>
      <AccountCircleIcon />
      <Typography sx={{ p: 1 }}>
        <FormattedMessage defaultMessage="{name}" values={user} />
      </Typography>
    </>
  );
}
