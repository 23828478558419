import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Typography, Grid, Button } from '@mui/material';
import MUILink from '@mui/material/Link';
import { useIntl } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';

/**
 * TODO: kopioi leikepöydälle -painike
 * @param {*} param0
 * @returns
 */
export default function OsallistumisLinkki({ toimisto }) {
  const intl = useIntl();

  const to = generatePath('/Ilmoittautuminen/:toimistoKoodi', {
    toimistoKoodi: toimisto.koodi,
  });

  const title = intl.formatMessage({ defaultMessage: 'Kopioi linkki' });

  return (
    <>
      <Grid container item xs={12} sx={{ pt: 1 }} alignItems="center">
        <Typography variant="subtitle2" sx={{ pr: 1 }}>
          <MUILink sx={{ pr: 1 }} component={Link} to={to} underline="none">
            {toimisto.koodi}
          </MUILink>
        </Typography>
        <Button
          aria-label={title}
          onClick={() =>
            navigator.clipboard.writeText(window.location.origin + to)
          }
        >
          {title}
          <ContentCopyIcon sx={{ pt: 0.5 }} />
        </Button>
      </Grid>
    </>
  );
}
