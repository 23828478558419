import OpenAPIClientAxios from 'openapi-client-axios';
import { create } from '@postinumero/use-async';
import definition from './schema.json';
const api = new OpenAPIClientAxios({ definition: definition });
export const clientPromise = api.init();
export async function getToimistotRaw(...args) {
    const client = await clientPromise;
    return await client['getToimistot'](...args);
}
export async function paivitaToimistoRaw(...args) {
    const client = await clientPromise;
    return await client['paivitaToimisto'](...args);
}
export async function luoToimistoRaw(...args) {
    const client = await clientPromise;
    return await client['luoToimisto'](...args);
}
export async function luoViestiRaw(...args) {
    const client = await clientPromise;
    return await client['luoViesti'](...args);
}
export async function vaihdaKoodiRaw(...args) {
    const client = await clientPromise;
    return await client['vaihdaKoodi'](...args);
}
export async function getOsallistumisetRaw(...args) {
    const client = await clientPromise;
    return await client['getOsallistumiset'](...args);
}
export async function postOsallistumisetRaw(...args) {
    const client = await clientPromise;
    return await client['postOsallistumiset'](...args);
}
export async function haeViestitRaw(...args) {
    const client = await clientPromise;
    return await client['haeViestit'](...args);
}
export async function getToimistoRaw(...args) {
    const client = await clientPromise;
    return await client['getToimisto'](...args);
}
export async function poistaToimistoRaw(...args) {
    const client = await clientPromise;
    return await client['poistaToimisto'](...args);
}
export async function poistaKayttajaRaw(...args) {
    const client = await clientPromise;
    return await client['poistaKayttaja'](...args);
}
export async function getToimistot(...args) {
    const response = await getToimistotRaw(...args);
    return response.data;
}
export async function paivitaToimisto(...args) {
    const response = await paivitaToimistoRaw(...args);
    return response.data;
}
export async function luoToimisto(...args) {
    const response = await luoToimistoRaw(...args);
    return response.data;
}
export async function luoViesti(...args) {
    const response = await luoViestiRaw(...args);
    return response.data;
}
export async function vaihdaKoodi(...args) {
    const response = await vaihdaKoodiRaw(...args);
    return response.data;
}
export async function getOsallistumiset(...args) {
    const response = await getOsallistumisetRaw(...args);
    return response.data;
}
export async function postOsallistumiset(...args) {
    const response = await postOsallistumisetRaw(...args);
    return response.data;
}
export async function haeViestit(...args) {
    const response = await haeViestitRaw(...args);
    return response.data;
}
export async function getToimisto(...args) {
    const response = await getToimistoRaw(...args);
    return response.data;
}
export async function poistaToimisto(...args) {
    const response = await poistaToimistoRaw(...args);
    return response.data;
}
export async function poistaKayttaja(...args) {
    const response = await poistaKayttajaRaw(...args);
    return response.data;
}
export const [useToimistotRaw, refetchToimistot, useToimistotRawSafe] = create(getToimistotRaw, { id: 'react-openapi-client-generator-getToimistot' });
export const [useOsallistumisetRaw, refetchOsallistumiset, useOsallistumisetRawSafe] = create(getOsallistumisetRaw, { id: 'react-openapi-client-generator-getOsallistumiset' });
export const [useHaeViestitRaw, refetchHaeViestit, useHaeViestitRawSafe] = create(haeViestitRaw, { id: 'react-openapi-client-generator-haeViestit' });
export const [useToimistoRaw, refetchToimisto, useToimistoRawSafe] = create(getToimistoRaw, { id: 'react-openapi-client-generator-getToimisto' });
export function useToimistot(...args) {
    return useToimistotRaw(...args).data;
}
export function useOsallistumiset(...args) {
    return useOsallistumisetRaw(...args).data;
}
export function useHaeViestit(...args) {
    return useHaeViestitRaw(...args).data;
}
export function useToimisto(...args) {
    return useToimistoRaw(...args).data;
}
export function useToimistotSafe(...args) {
    const [error, response] = useToimistotRawSafe(...args);
    return [error, response?.data];
}
export function useOsallistumisetSafe(...args) {
    const [error, response] = useOsallistumisetRawSafe(...args);
    return [error, response?.data];
}
export function useHaeViestitSafe(...args) {
    const [error, response] = useHaeViestitRawSafe(...args);
    return [error, response?.data];
}
export function useToimistoSafe(...args) {
    const [error, response] = useToimistoRawSafe(...args);
    return [error, response?.data];
}
