import { red, /*yellow, */ green } from '@mui/material/colors';
import { isSaturday, isSunday, nextMonday } from 'date-fns';

/*const OSALLISTUJA_MAARA = {
  VAHAN: 2,
  KOHTALAISESTI: 4,
  // paljon = yli 4
};*/

export function mappaaPalkki(osallistuminen) {
  return {
    ...osallistuminen,
    allDay: true,
    title: 'toimistolla',
  };
}

export function luoValittuPaiva(valittuPaiva) {
  return {
    start: valittuPaiva,
    allDay: true,
    display: 'background',
    backgroundColor: 'rgba(0,0,0,0)',
    classNames: ['valittu-paiva'],
  };
}

// todo: konfiguroitava?
function laskeOsallistujaMaaraVari(osallistujaMaara, kapasiteetti) {
  if (osallistujaMaara < kapasiteetti) {
    return green[200];
    /*} else if (osallistujaMaara < OSALLISTUJA_MAARA.KOHTALAISESTI) {
    return yellow[300]; */
  } else {
    return red[300];
  }
}

function luoOtsikko(osallistujaMaara) {
  return '🧍 ' + osallistujaMaara;
}

export function mappaaOsallistujaMaarat(paiva, kapasiteetti) {
  return {
    title: luoOtsikko(paiva.osallistujaMaara),
    start: paiva.start,
    allDay: true,
    display: 'background',
    backgroundColor: laskeOsallistujaMaaraVari(
      paiva.osallistujaMaara,
      kapasiteetti
    ),
    classNames: ['osallistujien-maara'],
  };
}

/**
 * Palauta annettu päivä, jos se on arkipäivä,
 * tai lähin seuraava arkipäivä.
 *
 * @returns Jos nyt on lauantai tai sunnuntai, palautuu seuraava maanantai.
 *          Muutoin palauta nykyinen päivä
 */
export function lahinArkipaiva(paiva) {
  if (isSaturday(paiva) || isSunday(paiva)) {
    return nextMonday(paiva);
  } else {
    return paiva;
  }
}
