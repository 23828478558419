import MUILink from '@mui/material/Link';
import { FormattedMessage } from 'react-intl';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Box from '@mui/material/Box';

/**
 * TODO: Linkit
 *
 * @returns
 */
export default function Linkit() {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <MUILink
          sx={{ pl: 1 }}
          target="_blank"
          underline="hover"
          href={
            'https://docs.google.com/forms/d/e/1FAIpQLSdUmR79YV6l9PPW6PmR54yw-xIj1RLk68KQUYQdULZ2N-oLzw/viewform'
          }
          color={'secondary.contrastText'}
        >
          <FormattedMessage defaultMessage="Anna palautetta" />
        </MUILink>
        <MUILink
          sx={{ pl: 1 }}
          underline="hover"
          href={'/tietoja'}
          color={'secondary.contrastText'}
        >
          <FormattedMessage defaultMessage="Tietoja" />
        </MUILink>
      </Box>
      <MUILink
        sx={{ pl: 2, pt: 1 }}
        target="_blank"
        underline="hover"
        href={'https://www.linkedin.com/company/ilimoapp/'}
        color={'secondary.contrastText'}
      >
        <LinkedInIcon />
      </MUILink>
    </>
  );
}
