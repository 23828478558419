import CssBaseline from '@mui/material/CssBaseline';
import LinearProgress from '@mui/material/LinearProgress';
import AppProvider from '@visma/react-app-super-template';
import { IsAuthenticated } from '@visma/react-keycloak';
import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Ilmoittautuminen from 'sivut/Ilmoittautuminen';
import Public from 'sivut/Public';
import ValitseToimisto from 'sivut/ValitseToimisto';
import BottomAppBar from './AppBar/BottomAppBar';
import TopAppBar from './AppBar/TopAppBar';
import Theme from './Theme';

function App() {
  return (
    <AppProvider fallback={<LinearProgress />}>
      <Theme>
        <CssBaseline />
        <IsAuthenticated fallback={<Public />}>
          <Router>
            <TopAppBar />
            <Suspense fallback={<LinearProgress />}>
              <Switch>
                <Route path="/Ilmoittautuminen/:toimistoKoodi">
                  <Ilmoittautuminen />
                </Route>
                <Route path="/">
                  <ValitseToimisto />
                </Route>
              </Switch>
            </Suspense>
            <BottomAppBar />
          </Router>
        </IsAuthenticated>
      </Theme>
    </AppProvider>
  );
}

export default App;
