import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import { useKeycloak } from '@visma/react-keycloak';
import { FormattedMessage } from 'react-intl';

export default function Logout({ sx }) {
  const { keycloak } = useKeycloak();

  return (
    <Button
      sx={sx}
      color="inherit"
      onClick={() => {
        keycloak.logout({ redirectUri: window.location.origin });
      }}
      endIcon={<LogoutIcon />}
    >
      <Hidden smDown>
        <FormattedMessage defaultMessage="Kirjaudu ulos" />
      </Hidden>
    </Button>
  );
}
