import { get } from 'lodash';
import { useState } from 'react';
import useKalenteri, { useOsallistujaMaarat } from 'hooks/useKalenteri';
import {
  luoValittuPaiva,
  mappaaOsallistujaMaarat,
  mappaaPalkki,
} from 'hooks/kalenteriUtils';
import Kalenteri from './Kalenteri';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import KalenteriKontrollit, { MuokkausTila } from './KalenteriKontrollit';
import { endOfMonth, isSameDay } from 'date-fns';
import { useDebouncedCallback } from 'use-debounce';
import { FormattedMessage } from 'react-intl';

/*
    EDIT:
        - omat osallistumiset OMAT_OSALLISTUMISET:
            - hae kaikki osallistumiset
            - suodata omat (sama osallistujaId kuin kayttajaId)
            - näytä palkkina:
            {
                title: "toimistolla",
                start: date,
                allDay: true,
                osallistujaId: kayttajaId
            }
        - päivittäminen:
            - lisää luonnoslistaan palkki
            {
                title: "toimistolla",
                start: date,
                allDay: true,
                osallistujaId: kayttajaId
            }
            - ulosmenevä pyyntö:
            {
                pvm: formatISO(start).split("T")[0]
            }
    VIEW:
        - omat osallistumiset OMAT_OSALLISTUMISET:
            - hae kaikki osallistumiset
            - jne. ks. ylhäältä
        - nykyinen päivä (luo valittuPaiva parametrista) 
            {
                title: "valittu päivä",
                start: valittuPaiva,
                allDay: true,
                display: "background"
            }
        - muiden osallistujat mapattyna:
            - hae kaikki osallistujat
            - laske osallistumiset per päivä
            - näytä lootana: 
             {
                title: osallistumistenMaara,
                start: valittuPaiva,
                allDay: true,
                display: "background"
                backgroundColor: laskeVari(osallistumistenMaara)
            }
*/

export default function KalenteriLomake({
  toimistoKoodi,
  kapasiteetti,
  muokkausTila,
  setMuokkausTila,
  valittuPaiva,
  setValittuPaiva,
  monthStart,
  setMonthStart,
}) {
  const [
    omatOsallistumiset,
    luonnos,
    aloitaLuonnos,
    paivitaLuonnos,
    peruutaLuonnos,
    tallenna,
  ] = useKalenteri(toimistoKoodi, monthStart, endOfMonth(monthStart));
  const osallistujaMaarat = useOsallistujaMaarat(
    toimistoKoodi,
    monthStart,
    endOfMonth(monthStart)
  );

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const kasitteleKlikkaus = (
    polku,
    event,
    kapasiteetti,
    osallistujaMaarat,
    omatOsallistumiset
  ) => {
    let paiva = get(event, polku);

    if (muokkausTila === MuokkausTila.VIEW) {
      setValittuPaiva(paiva);
    }
    if (muokkausTila === MuokkausTila.EDIT) {
      // Haetaan taulukosta päivän osallistujamäärä
      const osallistujaMaara = osallistujaMaarat.find((osallistujaMaara) =>
        isSameDay(osallistujaMaara.start, paiva)
      )?.osallistujaMaara;

      //haetaan omat osallistumiset (pitää tietää jos käyttäjä on perumassa osallistumistaan)
      const omaOsallistuminen = omatOsallistumiset.find((osallistuminen) =>
        isSameDay(osallistuminen.start, paiva)
      );

      // Verrataan onko osallistujaMaara vastaava kuin kapasiteetti -> Onko päivä täynnä?
      if (!omaOsallistuminen && kapasiteetti === osallistujaMaara) {
        setOpen(true);
      } else {
        paivitaLuonnos(paiva);
      }
    }
  };

  const kasitteleKlikkausDebounced = useDebouncedCallback(
    (polku, event, kapasiteetti, osallistujaMaarat, omatOsallistumiset) =>
      kasitteleKlikkaus(
        polku,
        event,
        kapasiteetti,
        osallistujaMaarat,
        omatOsallistumiset
      ),
    300,
    { trailing: false, leading: true }
  );

  let naytettavatTapahtumat = null;

  if (muokkausTila === MuokkausTila.EDIT) {
    naytettavatTapahtumat = luonnos.map(mappaaPalkki);
  } else {
    naytettavatTapahtumat = [
      ...osallistujaMaarat.map((maarat) =>
        mappaaOsallistujaMaarat(maarat, kapasiteetti)
      ),
      ...omatOsallistumiset.map(mappaaPalkki),
      luoValittuPaiva(valittuPaiva),
    ];
  }

  let validRange = function () {
    if (muokkausTila === MuokkausTila.EDIT) {
      return {
        start: monthStart,
        end: endOfMonth(monthStart),
      };
    }
  };

  return (
    <>
      <Kalenteri
        setMonthStart={(monthStart) => {
          peruutaLuonnos();
          setMuokkausTila(MuokkausTila.VIEW);
          setMonthStart(monthStart);
        }}
        monthStart={monthStart}
        validRange={validRange}
        editState={muokkausTila}
        events={naytettavatTapahtumat}
        dateClick={(event) =>
          kasitteleKlikkausDebounced(
            'date',
            event,
            kapasiteetti,
            osallistujaMaarat,
            omatOsallistumiset
          )
        }
        eventClick={(event) =>
          kasitteleKlikkausDebounced(
            'event.start',
            event,
            kapasiteetti,
            osallistujaMaarat,
            omatOsallistumiset
          )
        }
        setValittuPaiva={setValittuPaiva}
      />
      <KalenteriKontrollit
        muokkausTila={muokkausTila}
        tallenna={() => {
          tallenna();
          setMuokkausTila(MuokkausTila.VIEW);
        }}
        peruuta={() => {
          peruutaLuonnos();
          setMuokkausTila(MuokkausTila.VIEW);
        }}
        valitsePaivat={() => {
          aloitaLuonnos();
          setMuokkausTila(MuokkausTila.EDIT);
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage defaultMessage="Ilimoittautuminen ei ole mahdollista" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage defaultMessage="Valitsemallesi päivälle ilimoittautuminen ei onnistunut, koska toimiston kapasiteetti on tältä päivältä täynnä." />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage defaultMessage="Sulje" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
