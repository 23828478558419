import { Card, List, ListItem, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { useToimisto } from 'api';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { useValitunPaivanOsallistujat } from 'hooks/useKalenteri';
import { lahinArkipaiva } from 'hooks/kalenteriUtils';
import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import useDateFnsLocale from '@visma/react-app-locale-utils/lib/useDateFnsLocale.js';
import { MuokkausTila } from '../../components/KalenteriKontrollit';
import KalenteriLomake from '../../components/KalenteriLomake';
import Avatar from '../../components/Avatar';
import ToimistoKontrollit from '../../components/ToimistoKontrollit';

function ToimistonOsallistumiset({ osallistumiset }) {
  return (
    <Grid container spacing={2} component={List}>
      {[...osallistumiset]
        .sort((a, b) =>
          a.osallistujanNimi.localeCompare(b.osallistujanNimi, 'fi', {
            sensitivity: 'base',
          })
        )
        .map((osallistuminen) => (
          <Grid
            item
            xs={6}
            key={osallistuminen.osallistujaId}
            component={ListItem}
          >
            <Avatar
              sx={{ mr: 1 }}
              kayttajaNimi={osallistuminen.osallistujanNimi}
            />
            {osallistuminen.osallistujanNimi}
          </Grid>
        ))}
    </Grid>
  );
}

/*
Ilmoittautumis-sivu
kalenteri
toimiston nimi . kalenteritoiminnot
toimiston linkki
toimiston osallistujat

*/

export default function Ilmoittautuminen() {
  const locale = useDateFnsLocale();
  let aloitusPaiva = lahinArkipaiva(new Date());
  const [monthStart, setMonthStart] = useState(startOfMonth(aloitusPaiva));
  const [muokkausTila, setMuokkausTila] = useState(MuokkausTila.VIEW);
  // Oletuksena ensiksi valitaan tämä päivä
  const [valittuPaiva, setValittuPaiva] = useState(aloitusPaiva);
  const { toimistoKoodi } = useParams();
  const toimisto = useToimisto(toimistoKoodi);
  const osallistumiset = useValitunPaivanOsallistujat(
    toimistoKoodi,
    monthStart,
    endOfMonth(monthStart),
    valittuPaiva
  );

  return (
    <>
      <Container maxWidth="false">
        <Grid container spacing={2} flexWrap={'wrap-reverse'}>
          <Grid item md={6} xs={12}>
            <Hidden mdDown>
              {/* Spacer koska mobiilitaitto */}
              <Grid item sx={{ mt: 2 }} />
            </Hidden>
            <Hidden mdUp>
              <Card sx={{ px: 2, py: 1 }}>
                <ToimistoKontrollit
                  toimisto={toimisto}
                  toimistonNimiVariant="subtitle1"
                />
              </Card>
            </Hidden>
            <Card sx={{ mt: 1, px: 2, py: 1 }}>
              <Typography>
                <FormattedMessage
                  defaultMessage="{date}"
                  values={{
                    date: format(valittuPaiva, 'eeee P', { locale }),
                  }}
                />
              </Typography>
              <ToimistonOsallistumiset osallistumiset={osallistumiset} />
            </Card>
          </Grid>
          <Grid item md={6} xs={12}>
            <Card sx={{ mt: 2, pr: 2, pl: 2, pt: 2 }}>
              <KalenteriLomake
                kapasiteetti={
                  toimisto.kapasiteetti > 0 ? toimisto.kapasiteetti : 5000
                }
                valittuPaiva={valittuPaiva}
                setValittuPaiva={setValittuPaiva}
                muokkausTila={muokkausTila}
                setMuokkausTila={setMuokkausTila}
                toimistoKoodi={toimistoKoodi}
                monthStart={monthStart}
                setMonthStart={setMonthStart}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
