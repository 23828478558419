import { useKeycloak } from '@react-keycloak/web';

export default function useKayttaja() {
  const {
    keycloak: { tokenParsed },
  } = useKeycloak();

  return {
    nimi: tokenParsed.name,
    kayttajaId: tokenParsed.sub,
  };
}
