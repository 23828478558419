import { Hidden, Stack } from '@mui/material';
import MUIAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useToimisto } from 'api';
import ToimistoKontrollit from 'components/ToimistoKontrollit';
import { useParams } from 'react-router';
import { Route } from 'react-router-dom';
import Logout from './Logout';
import User from './User';

function AppBarToimistoKontrollit() {
  const { toimistoKoodi } = useParams();
  let toimisto = useToimisto(toimistoKoodi);
  return <ToimistoKontrollit toimisto={toimisto} toimistonNimiVariant="h4" />;
}

function DesktopAppBar() {
  return (
    <MUIAppBar
      position="static"
      sx={{ bgcolor: 'topbar.main', color: 'topbar.contrastText' }}
    >
      <Toolbar>
        <Route path="/Ilmoittautuminen/:toimistoKoodi">
          <AppBarToimistoKontrollit />
        </Route>
        <Stack direction="row" alignItems="center" marginLeft="auto">
          <User />
          <Logout />
        </Stack>
      </Toolbar>
    </MUIAppBar>
  );
}

function MobileAppBar() {
  return (
    <MUIAppBar
      position="static"
      sx={{ bgcolor: 'topbar.main', color: 'topbar.contrastText' }}
    >
      <Toolbar>
        <User />
        <Logout sx={{ marginLeft: 'auto' }} />
      </Toolbar>
    </MUIAppBar>
  );
}

export default function TopAppBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Hidden mdDown>
        <DesktopAppBar />
      </Hidden>
      <Hidden mdUp>
        <MobileAppBar />
      </Hidden>
    </Box>
  );
}
