import { isEmpty, trim, isInteger } from 'lodash';
import { Button, Divider, TextField, Typography } from '@mui/material';
import { luoToimisto, refetchToimistot } from 'api';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export default function LisaaToimisto() {
  const intl = useIntl();
  const [toimistonNimi, setToimistonNimi] = useState('');
  const [toimistonKapasiteetti, setToimistonKapasiteetti] = useState('');
  const [nimiVirhe, setNimiVirhe] = useState(null);
  const [kapasiteettiVirhe, setKapasiteettiVirhe] = useState(null);

  function validoi() {
    var result = true;
    if (isEmpty(trim(toimistonNimi))) {
      setNimiVirhe('Nimi on tyhjä');
      result = false;
    }

    if (
      (isEmpty(toimistonKapasiteetti) && !isInteger(toimistonKapasiteetti)) ||
      toimistonKapasiteetti < 1
    ) {
      setKapasiteettiVirhe('Kapasiteetin pitää olla kokonaisluku.');
      result = false;
    }
    return result;
  }

  return (
    <>
      <Divider />
      <Typography variant="subtitle1" sx={{ mt: 3 }}>
        <FormattedMessage defaultMessage="Lisää uusi toimisto" />
      </Typography>
      <TextField
        fullWidth
        onChange={(e) => {
          setNimiVirhe(null);
          setToimistonNimi(e.target.value);
        }}
        value={toimistonNimi}
        variant="outlined"
        label={intl.formatMessage({
          defaultMessage: 'Toimiston nimi',
        })}
        error={!isEmpty(nimiVirhe)}
        required
        helperText={nimiVirhe}
        sx={{ mt: 3, mb: 1 }}
      />
      <TextField
        fullWidth
        onChange={(e) => {
          setKapasiteettiVirhe(null);
          setToimistonKapasiteetti(e.target.value);
        }}
        value={toimistonKapasiteetti}
        variant="outlined"
        label={intl.formatMessage({
          defaultMessage: 'Toimiston kapasiteetti',
        })}
        error={!isEmpty(kapasiteettiVirhe)}
        helperText={kapasiteettiVirhe}
        required
        sx={{ mt: 1, mb: 2 }}
        type="number"
      />
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={async () => {
          if (!validoi()) return;

          await luoToimisto(null, {
            nimi: trim(toimistonNimi),
            kapasiteetti: toimistonKapasiteetti,
          });
          await refetchToimistot();
          setToimistonNimi('');
          setToimistonKapasiteetti('');
        }}
      >
        <FormattedMessage defaultMessage="Lisää toimisto" />
      </Button>
    </>
  );
}
