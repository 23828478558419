import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
} from '@mui/material';
import {
  paivitaToimisto,
  poistaToimisto,
  useToimistot,
  refetchToimistot,
} from 'api';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';
import IsOmistaja from './IsOmistaja';

function Toimistot() {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const [toimistonNimi, setToimistonNimi] = React.useState('');
  const [toimistonKapasiteetti, setToimistonKapasiteetti] = React.useState(0);
  const [toimistonId, setToimistonId] = React.useState('');
  const [alertAvattu, setAlertAvattu] = React.useState(false);

  const avaaDialogi = (toimistonNimi, toimistonKapasiteetti, toimistonId) => {
    setOpen(true);
    setToimistonNimi(toimistonNimi);
    setToimistonKapasiteetti(toimistonKapasiteetti);
    setToimistonId(toimistonId);
  };

  const suljeDialogi = () => {
    setOpen(false);
  };

  const avaaAlert = () => {
    setAlertAvattu(true);
  };

  const suljeAlert = () => {
    setAlertAvattu(false);
  };

  const tallenna = async () => {
    await paivitaToimisto({
      id: toimistonId,
      nimi: toimistonNimi,
      kapasiteetti: toimistonKapasiteetti,
    });
    setOpen(false);
  };

  const poista = async () => {
    await poistaToimisto({
      toimistoId: toimistonId,
    });
    setAlertAvattu(false);
    setOpen(false);
    await refetchToimistot();
  };

  const toimistot = useToimistot();

  return (
    <div>
      <List>
        {toimistot.map((toimisto) => (
          <ListItem
            key={toimisto.id}
            secondaryAction={
              <IsOmistaja toimisto={toimisto}>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  style={{ color: '#2C3E50' }}
                  onClick={() =>
                    avaaDialogi(
                      toimisto.nimi,
                      toimisto.kapasiteetti,
                      toimisto.id
                    )
                  }
                >
                  <EditLocationAltIcon />
                </IconButton>
              </IsOmistaja>
            }
          >
            <ListItemButton
              component={Link}
              to={generatePath('/Ilmoittautuminen/:toimistoKoodi', {
                toimistoKoodi: toimisto.koodi,
              })}
            >
              <ListItemText primary={toimisto.nimi} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Dialog open={open} onClose={suljeDialogi}>
        <DialogTitle>{toimistonNimi}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage defaultMessage="Tällä sivulla voit muokata toimistosi tietoja kuten nimeä ja toimiston kapasiteettia." />
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            label={intl.formatMessage({
              defaultMessage: 'Toimiston nimi',
            })}
            fullWidth
            required
            variant="outlined"
            value={toimistonNimi}
            onChange={(e) => {
              setToimistonNimi(e.target.value);
            }}
          />
          <TextField
            margin="dense"
            id="name"
            label={intl.formatMessage({
              defaultMessage: 'Toimiston kapasiteetti',
            })}
            fullWidth
            required
            variant="outlined"
            value={toimistonKapasiteetti}
            onChange={(e) => {
              setToimistonKapasiteetti(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={avaaAlert}>
            <FormattedMessage defaultMessage="Poista" />
          </Button>
          <div style={{ flex: '1 0 0' }} />
          <Button variant="contained" onClick={suljeDialogi}>
            <FormattedMessage defaultMessage="Peruuta" />
          </Button>
          <Button variant="contained" color="secondary" onClick={tallenna}>
            <FormattedMessage defaultMessage="Tallenna" />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={alertAvattu} onClose={suljeAlert}>
        <DialogTitle>
          <FormattedMessage defaultMessage="Haluatko varmasti poistaa toimiston " />{' '}
          {toimistonNimi}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage defaultMessage="Olet poistamassa toimistoa. Kaikki toimistoon liittyvät tiedot menetetään. Haluatko varmasti jatkaa?" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={suljeAlert}>
            <FormattedMessage defaultMessage="Peruuta" />
          </Button>
          <Button variant="contained" color="error" onClick={poista}>
            <FormattedMessage defaultMessage="Poista" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Toimistot;
