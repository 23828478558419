import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import { useKeycloak } from '@react-keycloak/web';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import logo from '../../kuvat/logo_nimella.png';
import mobiiliMuokkaus from '../../kuvat/ilimo_mobiili_muokkaus.png';
import ilimoittautuminen from '../../kuvat/ilimoittautuminen.png';
import ilimoittautuminenMobiili from '../../kuvat/ilimoittautuminen_mobiili.png';

export default function Public() {
  const { keycloak } = useKeycloak();
  const intl = useIntl();

  return (
    <Container component="main" maxWidth="xs">
      <Card
        sx={{
          mt: 8,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          style={{
            filter: 'drop-shadow(2px 4px 6px black)',
            width: '100%',
            height: 'auto',
          }}
          src={logo}
          alt={'ilimo'}
        />
        <Button
          component="a"
          href={keycloak.createRegisterUrl()}
          fullWidth
          size="large"
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          <FormattedMessage defaultMessage="Rekisteröidy" />
        </Button>
        <Button
          component="a"
          href={keycloak.createLoginUrl()}
          fullWidth
          sx={{ mb: 2 }}
          size="large"
          variant="outlined"
        >
          <FormattedMessage defaultMessage="Kirjaudu" />
        </Button>
        <Button
          component="a"
          href={keycloak.createLoginUrl({ idpHint: 'google' })}
          fullWidth
          sx={{ mb: 2 }}
          size="large"
          startIcon={<GoogleIcon />}
          variant="outlined"
          color="secondary"
        >
          <FormattedMessage defaultMessage="Google" />
        </Button>
        <Button
          component="a"
          href={keycloak.createLoginUrl({ idpHint: 'linkedin' })}
          fullWidth
          size="large"
          startIcon={<LinkedInIcon />}
          variant="outlined"
          color="secondary"
        >
          <FormattedMessage defaultMessage="LinkedIn" />
        </Button>
      </Card>
      <Card
        sx={{
          mt: 1,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          sx={{ mt: 4, mb: 1 }}
        >
          <FormattedMessage
            defaultMessage="Ilimo on aputyökalu, jolla voit ilmoittaa toimistolla
          paikallaolopäivät ja tarkistaa milloin muut työkaverisi ovat paikalla.
          Ilimo myös huolehtii toimistosi paikkarajoituksista."
          />
        </Typography>
        <ImageList variant="quilted" cols={4}>
          <ImageListItem
            key="ilimo"
            cols="4"
            component="a"
            href={ilimoittautuminen}
            target="_blank"
          >
            <img src={ilimoittautuminen} alt="Ilmoittauduttu" loading="lazy" />
          </ImageListItem>

          <ImageListItem
            key="editmob"
            cols="2"
            component="a"
            href={mobiiliMuokkaus}
            target="_blank"
          >
            <img
              src={mobiiliMuokkaus}
              alt="Ilimon ilmoitttautuminen"
              loading="lazy"
            />
          </ImageListItem>

          <ImageListItem
            key="to_mob"
            cols="2"
            component="a"
            href={ilimoittautuminenMobiili}
            target="_blank"
          >
            <img
              src={ilimoittautuminenMobiili}
              alt="Ilimon toimistonäkymä mobiilissa"
              loading="lazy"
            />
          </ImageListItem>
        </ImageList>
      </Card>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: 4, mb: 1 }}
      >
        <FormattedMessage
          defaultMessage="Palvelun tarjoaa <Link>Ilimo</Link>"
          values={{
            Link: (chunks) => (
              <Link
                color="inherit"
                href="https://www.linkedin.com/company/ilimoapp/"
              >
                {chunks}
              </Link>
            ),
          }}
        />
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mb: 1 }}
      >
        <Link
          color="inherit"
          href={intl.formatMessage({
            defaultMessage: '/tietoja',
          })}
        >
          <FormattedMessage defaultMessage="Tietoja" />
        </Link>
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mb: 4 }}
      >
        <FormattedMessage
          defaultMessage="© {year}"
          values={{
            year: new Date().getFullYear(),
          }}
        />
      </Typography>
    </Container>
  );
}
